:root {
  --tamanho-menor: 20%;
  --tamanho-maior: 80%;
}
@media print {
  @page {
    size: auto;
    margin: 8mm;
  }
  html, body {
    visibility: hidden;
    width: 100%;
    color: black !important;
  }

  body * {
    visibility: hidden;
  }

  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }

  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }

  .size-14 {
    font-size: 14px !important;
  }

  .y-margin-50 {
    margin: 50px 0;
  }

  .color-black {
    color: rgb(15, 15, 15) !important;
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 15px;
  }

  .vendedor-orcamento {
    width: var(--tamanho-menor) !important;
    display: flex;
    justify-content: flex-end;
  }

  .identificacao-orcamento {
    width: var(--tamanho-maior) !important;
  }

  .tabela-produtos-index {
    text-align: start;
    width:20px !important;
    padding: 0 0 0 20px;
    font-weight: 400;
  }

  .titulo-tabela-produtos {
    width: 100px !important;
    padding: 0 0 0 20px;
  }

  .totalizadores {
    justify-content: space-evenly;
    border: 1px solid black;
    padding: 20px;
    border-top: none;
    display: flex;
  }

  .sub-total, .valor-sub-total {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .sub-total {
    width: 90% !important;
  }

  .valor-sub-total {
    width: 10% !important;
  }

  .color-print {
    color: #263238
  }
}


.container-logo {
  width: 80px !important;
}

.img-logo {
  width: 70px !important;
  height: 70px !important;
}

.w-20 {
  width: 100px !important;
}

.w-100 {
  width: 100px !important;
}

.w-400 {
  width: 400px !important;
  text-align: initial;
}

.totalizadores {
  justify-content: space-evenly;
  border: 1px solid black;
  padding: 20px;
  border-top: none;
  display: flex
}